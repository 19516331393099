import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import app from "gatsby-plugin-firebase-v9.0";
import GlobalLayout from "./src/components/Layout";
import theme from "./src/theme";
import AuthProvider from "./src/context/auth";

export const wrapPageElement = ({ element, props }) => (
  <AuthProvider>
    <ChakraProvider theme={theme}>
      <GlobalLayout {...props}>{element}</GlobalLayout>
    </ChakraProvider>
  </AuthProvider>
);
