import { extendTheme, theme as base } from "@chakra-ui/react";

const brandRing = {
  _focus: {
    ring: 2,
    ringColor: "ffBlue",
  },
};

const primaryButtonStyles = {
  ...brandRing,
  backgroundColor: "ffBlue",
  color: "white",

  _hover: {
    backgroundColor: "#0180c3",
  },
  _active: {
    backgroundColor: "#0073af",
  },
  _loading: {
    _hover: {
      backgroundColor: "#0180c3",
    },
  },
  _disabled: {
    _hover: {
      backgroundColor: "#0180c3 !important",
    },
  },
};

const secondaryButtonStyles = {
  backgroundColor: "white",
  color: "ffBlue",
  borderColor: "ffBlue",
  border: "2px solid",

  _focus: {
    ring: 1,
    ringColor: "ffBlue",
  },
  _hover: {
    backgroundColor: "gray.100",
  },
  _active: {
    backgroundColor: "gray.200",
  },
  _loading: {
    _hover: {
      backgroundColor: "gray.100",
    },
  },
  _disabled: {
    _hover: {
      backgroundColor: "gray.100 !important",
    },
  },
};

const inputSelectStyles = {
  variants: {
    primary: {
      field: {
        border: "2px solid",
        borderColor: "inherit",
        borderRadius: "none",

        _invalid: {
          borderColor: "ffRed",
        },
        _focus: {
          zIndex: 1,
          borderColor: "ffBlue",
        },
      },
    },
  },
};

const theme = extendTheme({
  colors: {
    ffBlue: "#009beb",
    ffRed: "#ff4949",
  },
  fonts: {
    heading: `Montserrat, ${base.fonts?.heading}`,
    body: `Inter, ${base.fonts?.body}`,
  },
  components: {
    Button: {
      variants: {
        primary: {
          rounded: "none",
          ...primaryButtonStyles,
        },
        primaryRounded: {
          ...primaryButtonStyles,
        },
        secondary: {
          rounded: "none",
          ...secondaryButtonStyles,
        },
        secondaryRounded: {
          ...secondaryButtonStyles,
        },
      },
    },
    Input: {
      ...inputSelectStyles,
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderRadius: 4,
          borderColor: "gray.400",
          ...brandRing,
        },
      },
    },
  },
});

export default theme;
