import React from "react";
import PropTypes from "prop-types";
import { Container, VStack } from "@chakra-ui/react";
import SEO from "./seo";
import "../theme/styles.css";
// eslint-disable-next-line import/order
import { injectIntl } from "gatsby-plugin-intl";

const GlobalLayout = ({ children }) => {
  return (
    <>
      <SEO
        title='Fundação Fenômenos'
        description=''
        image="./doar_back.jpg"
        slug="/"
      />
      <Container
        bg="ffBlue"
        maxW="full"
        height={{ base: "auto", sm: "100vh" }}
        minH={{ base: "100vh", sm: 600 }}
        px={0}
        overflow="scroll"
      >
        <VStack
          height="full"
          mt="auto"
          mb="auto"
          alignItems="center"
          justifyContent="center"
        >
          {children}
        </VStack>
      </Container>
    </>
  )
};

GlobalLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default GlobalLayout;
