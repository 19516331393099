exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-doar-jsx": () => import("./../../../src/pages/doar.jsx" /* webpackChunkName: "component---src-pages-doar-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-obrigado-jsx": () => import("./../../../src/pages/obrigado.jsx" /* webpackChunkName: "component---src-pages-obrigado-jsx" */),
  "component---src-pages-resetar-senha-jsx": () => import("./../../../src/pages/resetar-senha.jsx" /* webpackChunkName: "component---src-pages-resetar-senha-jsx" */)
}

