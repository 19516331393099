import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import app from "gatsby-plugin-firebase-v9.0";
import { getAuth, browserLocalPersistence } from "firebase/auth";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();

  useEffect(async () => {
    const setAuth = async () => {
      const auth = await getAuth(app);
      auth.updateCurrentUser();
      await auth.setPersistence(browserLocalPersistence);
      await auth.onAuthStateChanged(setUser);
    };
    setAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthProvider;
